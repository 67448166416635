const associationMenuConst: any[] = [
  {
    id: "general",
    name: "General Information",
    slug: "general",
  },
  {
    id: "compliance",
    name: "Compliance",
    slug: "reporting",
  },
  {
    id: "accounting",
    name: "Accounting",
    slug: "financials",
  },
  {
    id: "management",
    name: "Management & Policies",
    slug: "policy",
  },
  {
    id: "board",
    name: "Board of Directors",
    slug: "group",
  },
  {
    id: "committees",
    name: "Committees",
    slug: "briefcase",
  },
  {
    id: "meetings",
    name: "Meetings",
    slug: "calendar",
  },
  {
    id: "leasing",
    name: "Leasing",
    slug: "key",
  },
  {
    id: "portal",
    name: "Website Portal",
    slug: "calendar",
  },
  {
    id: "printing",
    name: "Printing",
    slug: "printer",
  },
];

const unitMenuConst: any[] = [
  {
    id: "general",
    name: "General Information",
    slug: "general",
  },
  {
    id: "photos",
    name: "Photos",
    slug: "contact-personal",
  },
  {
    id: "amenities",
    name: "Amenities",
    slug: "contact-location",
  },
];

const customerMenuConst: any[] = [
  {
    id: "personal",
    name: "Personal Info",
    slug: "contact-personal",
  },
  {
    id: "resident",
    name: "Resident",
    slug: "contact-info",
  },
  {
    id: "address",
    name: "Address",
    slug: "contact-location",
  },
  {
    id: "property",
    name: "Property",
    slug: "contact-property",
  },
  {
    id: "document",
    name: "Document",
    slug: "contact-document",
  },
];

const ownerMenuConst: any[] = [
  {
    id: "personal",
    name: "Personal Info",
    slug: "contact-personal",
  },
  {
    id: "contact",
    name: "Contact Info",
    slug: "contact-info",
  },
  {
    id: "address",
    name: "Address",
    slug: "contact-location",
  },
  {
    id: "boardMemberHistory",
    name: "Board Member History",
    slug: "board-member-history",
  },
  // {
  //   id: "emergency",
  //   name: "Emergency Contact",
  //   slug: "contact-emergency",
  // },
  // {
  //   id: "marketing",
  //   name: "Marketing Profile",
  //   slug: "contact-marketing",
  // },
  // {
  //   id: "pet",
  //   name: "Pets",
  //   slug: "contact-pet",
  // },
  // {
  //   id: "vehicle",
  //   name: "Vehicles",
  //   slug: "contact-vehicle",
  // },
  // {
  //   id: "dependent",
  //   name: "Dependents",
  //   slug: "contact-dependent",
  // },
  // {
  //   id: "payment",
  //   name: "Send Payments",
  //   slug: "contact-payment",
  // },
  // {
  //   id: "document",
  //   name: "Document",
  //   slug: "contact-document",
  // },
];

const tenantMenuConst: any[] = [
  {
    id: "personal",
    name: "Personal Info",
    slug: "contact-personal",
  },
  {
    id: "contact",
    name: "Contact Info",
    slug: "contact-info",
  },
  {
    id: "address",
    name: "Address",
    slug: "contact-location",
  },
  // {
  //   id: "emergency",
  //   name: "Emergency Contact",
  //   slug: "contact-emergency",
  // },
  // {
  //   id: "marketing",
  //   name: "Marketing Profile",
  //   slug: "contact-marketing",
  // },
  // {
  //   id: "pet",
  //   name: "Pets",
  //   slug: "contact-pet",
  // },
  // {
  //   id: "vehicle",
  //   name: "Vehicles",
  //   slug: "contact-vehicle",
  // },
  // {
  //   id: "dependent",
  //   name: "Dependents",
  //   slug: "contact-dependent",
  // },
  // {
  //   id: "payment",
  //   name: "Send Payments",
  //   slug: "contact-payment",
  // },
  // {
  //   id: "document",
  //   name: "Document",
  //   slug: "contact-document",
  // },
];

const vendorMenuConst: any[] = [
  // {
  //   id: "personal",
  //   name: "Personal Info",
  //   slug: "contact-personal",
  // },
  {
    id: "vendor",
    name: "Vendor Info",
    slug: "contact-personal",
  },
  {
    id: "employee",
    name: "Employee Info",
    slug: "contact-info",
  },
  {
    id: "address",
    name: "Address",
    slug: "contact-location",
  },
  {
    id: "insurance",
    name: "Insurance",
    slug: "contact-emergency",
  },
  {
    id: "service",
    name: "Services",
    slug: "contact-service",
  },
  {
    id: "property",
    name: "Property",
    slug: "contact-property",
  },
  {
    id: "taxes",
    name: "Federal Taxes",
    slug: "contact-payment",
  },
  {
    id: "document",
    name: "Document",
    slug: "contact-document",
  },
];

const employeeMenuConst: any[] = [
  {
    id: "personal",
    name: "Personal Info",
    slug: "contact-personal",
  },
  {
    id: "contact",
    name: "Contact Info",
    slug: "contact-info",
  },
  {
    id: "address",
    name: "Address",
    slug: "contact-location",
  },
  {
    id: "insurance",
    name: "Insurance",
    slug: "contact-emergency",
  },
  {
    id: "document",
    name: "Document",
    slug: "contact-document",
  },
];

const leasingMenuConst: any[] = [];

export const GlobalFormMenuConst: any = {
  association: associationMenuConst,
  unit: unitMenuConst,
  customer: customerMenuConst,
  employee: employeeMenuConst,
  leasing: leasingMenuConst,
  owner: ownerMenuConst,
  tenant: tenantMenuConst,
  vendor: vendorMenuConst,
};
