export const ContactAgentTableKeyConst = [
  "name",
  "groups.0.name",
  "contact",
  "selection",
];

export const ContactAgentTableLabelConst = ["Name", "Company", "Contact", ""];

export const ContactAgentTableSortKeyConst = [];

export const ContactCustomerTableKeyConst = [
  "name",
  "address",
  "accountNumber",
  "generation",
  "totalBalance",
  "portalStatus",
  "selection",
];

export const ContactCustomerTableLabelConst = [
  "Name",
  "Address",
  "Account",
  "Generation",
  "Account Balance",
  "Resident Portal",
  "",
];

export const ContactCustomerTableSortKeyConst = [];

export const ContactDeveloperTableKeyConst = [
  "name",
  "groups.0.name",
  "contact",
  "selection",
];

export const ContactDeveloperTableLabelConst = [
  "Name",
  "Company",
  "Contact",
  "",
];

export const ContactDeveloperTableSortKeyConst = [];

export const ContactEmployeeTableKeyConst = [
  "name",
  "contact",
  "groupRoleName",
  "countProperties",
  "portalStatus",
  "selection",
];

export const ContactEmployeeTableLabelConst = [
  "Name",
  "Contact",
  "Role",
  "# Of Properties",
  "Portal",
  "",
];

export const ContactEmployeeTableSortKeyConst = [];

export const ContactManagerTableKeyConst = ["name", "unitCount", "selection"];

export const ContactManagerTableLabelConst = ["Name", "Units Managed", ""];

export const ContactManagerTableSortKeyConst = [];

export const ContactOwnerTableKeyConst = [
  "name",
  "address",
  "contact",
  "portalStatus",
  "selection",
];

export const ContactOwnerTableLabelConst = [
  "Name",
  "Address",
  "Contact",
  "Portal",
  "",
];

export const ContactOwnerTableSortKeyConst = [];

export const ContactTenantTableKeyConst = [
  "name",
  "address",
  "contact",
  "portalStatus",
  "selection",
];

export const ContactTenantTableLabelConst = [
  "Name",
  "Address",
  "Contact",
  "Portal",
  "",
];

export const ContactTenantTableSortKeyConst = [];

export const ContactVendorTableKeyConst = [
  "name",
  "contact",
  "type",
  "countUsers",
  "countProperties",
  "totalBalance",
  "selection",
];

export const ContactVendorTableLabelConst = [
  "Name",
  "Contact",
  "Vendor Type",
  "# Of Users",
  "# Of Properties",
  "Balance",
  "",
];

export const ContactVendorTableSortKeyConst = [];

//for property menu

export const ContactVendorTablePropertyConst = ["units", "city", "action"];

export const ContactVendorTablePropertyLabelConst = [
  "Units",
  "City,State",
  "Action",
];

export const ContactVendorTablePropertySortKeyConst = ["units"];
